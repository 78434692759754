<template lang="pug">
.container.container-homepage.mt-4
  content-renderer(:renderData="content")
</template>

<script>
import ContentRenderer from "./components/DynamicContent/DynamicContentRenderer.vue";
import resDefs from "../../../portal/pages/admin/definitions";
import { nukeStrapiCache } from "@/utils";

export default {
  name: "PreviewPage",
  components: {
    ContentRenderer,
  },
  data() {
    const cmdName = "nukeCache";
    const cmdModel = {
      cacheKeyToBeNuked: "Strapi DynamicPages"
    };
    const slug = this.$route.query.slug;
    const secret = this.$route.query.secret;
    return {
      slug,
      secret,
      cmdName,
      resDef: resDefs.cliTools,
      cmdModel,
      content: [],
      contentLoaded: false,
    };
  },
  computed: {
    cmdDef() {
      return resDefs.cliTools.cmds[this.cmdName];
    },
  },
  async mounted() {
    try {
      await nukeStrapiCache("Strapi DynamicPages", this, this.resDef.upsertMutation);
    } catch (e) {
      console.log(e);
    }
    if (!this.secret) {
      this.$router.push("/error/not-found");
      return;
    }
    const result = await this.$apollo.query({
      query: resDefs.strapiContents.filteredListQuery,
      variables: {
        filter: {
          collectionType: "DynamicPage",
          slugs: [this.slug],
          publicationState: "PREVIEW",
          previewPageSecret: this.secret,
        },
      },
    });
    result?.data?.strapiContents?.edges.forEach((content) => {
      this.content = content.node?.dynamicContent;
    });
    if (this.content.length === 0) {
      this.$router.push("/error/not-found");
      return;
    } 
    this.contentLoaded = true;
    
  },
};
</script>

